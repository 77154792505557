
export const basicCards = [
    "Copper",
    "Silver",
    "Gold",
    "Estate",
    "Duchy",
    "Province",
    "Curse"
];

export const stackPileCardTypes = [
    "Townsfolk",
    "Wizard",
    "Fort",
    "Augur",
    "Clash",
    "Odyssey",
    "Castle"
];

export const tokenTypes = [
    "Coffers",
    "Favor",
    "Villager",
    "Journey token",
    "Action token",
    "-[Coin] token",
    "-1 Card token",
    "Trashing token",
    "Tavern mat",
    "Estate token",
    "+1 Card token",
    "Buy token",
    "-2[Coin] cost token"
];

export const additionalCardTypes = [
    "Ruins",
    "Event",
    "Hex",
    "Boon",
    "Shelter",
    "Spoils",
    "State",
    "Heirloom",
    "Zombie",
    "Spirit",
    "Way",
    "Artifact",
    "Knight",
    "Trait",
    "Landmark",
    "Project",
    "Ally",
    "Loot",
    "Prize",
    "Prophecy",
    ...stackPileCardTypes,
    ...tokenTypes
];

export const optionalCardTypes = [
    "Trait",
    "Event",
    "Landmark",
    "Way",
    "Project",
    "Ally",
    "Prophecy"
]

export const additionalCards = [
    "Curse",
    "Potion",
    "Platinum",
    "Colony",
    "Spoils",
    "Curse",
    "Wish"
];

export const landscapeTypes = [
    "Boon",
    "Hex",
    "State",
    "Artifact",
    ...optionalCardTypes
];

export const doNotShowAdditionalCardTypes = [
    "Heirloom",
    "Spoils"
];

export const requiredOtherTextSearches: { query: string | string[], result: string }[] = [
    {
        query:
            [
                "+1[vp", "add 1[vp]", "take 1[vp]"
            ],
        result: "VP Token"
    },
    {
        query: "embargo",
        result: "Embargo Token"
    },
    {
        query: "[debt]",
        result: "Debt Token"
    },
    {
        query: "Omen",
        result: "Sun Token"
    }
];