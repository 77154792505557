import { additionalCardTypes, doNotShowAdditionalCardTypes, requiredOtherTextSearches, stackPileCardTypes } from "../../Data/CardRules";
import { CardHelper } from "../../Helpers/CardHelper";
import { Card } from "../Card";
import { AdditionalCards, CardSet, CardSets, RequiredOther } from "../CardSet";

export class CardsMeta {

    private readonly _cardSets: CardSets;
    public get CardSets() { return this._cardSets; }

    private readonly _otherCards: AdditionalCards;
    public get OtherCards() { return this._otherCards; }

    private readonly _allCards: Card[];

    constructor(cardSets: CardSets, otherCards: AdditionalCards) {

        this._otherCards = otherCards;
        this._cardSets = cardSets;

        const allCards = [...this._otherCards];

        for (let key of Object.keys(this._cardSets)) {
            for (let ed of Object.keys(this._cardSets[key])) {
                allCards.push(...this._cardSets[key][ed].cards);
            }
        }

        this._allCards = allCards;
    }

    public getRequiredOtherCardsForCards(kingdomCards: Card[], optionalCards: Card[]): RequiredOther {

        const cardsToCheck = [...kingdomCards, ...optionalCards];
        const newlyRequiredCards: Card[] = [...optionalCards];
        const newlyRequiredCardTypes: string[] = [];

        for (const card of cardsToCheck) {
            const { cards, cardTypes } = this.getRequiredOtherCardsForCard(card);

            for (const c of cards) {
                newlyRequiredCards.push(c);
            }

            for (const c of cardTypes) {
                newlyRequiredCardTypes.push(c!);
            }
        }

        // check the cards that may have just been added
        for (const card of newlyRequiredCards) {
            const { cards, cardTypes } = this.getRequiredOtherCardsForCard(card);

            for (const c of cards) {
                if (newlyRequiredCards.find(x => x.name === c.name) === undefined) {
                    newlyRequiredCards.push(c);
                }
            }

            for (const c of cardTypes) {
                newlyRequiredCardTypes.push(c!);
            }
        }

        const cardTypes = [...new Set(newlyRequiredCardTypes)];
        const names = [...new Set(newlyRequiredCards.map(x => x.name))];
        const cards = names
            .map(x => newlyRequiredCards.find(y => y.name === x))
            .filter(x => x !== undefined)
            .map(x => x!);

        return {
            cards,
            cardTypes: cardTypes
        };
    }

    private getRequiredOtherCardsForCard(card: Card): RequiredOther {

        const cards: Card[] = [];
        const cardTypes: string[] = [];

        if (card.cost.includes("P")) {
            cards.push(this.getCard("Potion")!);
        }

        if (card.cost.includes("D")) {
            cardTypes.push("Debt Token");
        }

        // Process text body of the card
        const lower = card.text.toLowerCase();

        for (const search of requiredOtherTextSearches) {
            if (typeof search.query === "string") {
                if (lower.includes(search.query)) {
                    cardTypes.push(search.result);
                } else if (card.types.indexOf(search.query) !== -1) {
                    cardTypes.push(search.result);
                }
            } else {
                for (const query of search.query) {
                    if (lower.includes(query)) {
                        cardTypes.push(search.result);
                        break;
                    }
                }
            }
        }

        for (let otherCard of this._otherCards) {

            if (otherCard.name === card.name) {
                continue;
            }

            // Some card types are always stacks and aren't referred to specifically
            // In Hexes there is a card called "War" which conflicts with "War Chest" text
            // In events there is a card called "Journey" which conflicts with Journey mat references
            if (otherCard.types.includes("Hex") || otherCard.types.includes("Boon") || otherCard.types.includes("Event")) {
                continue;
            }

            if (card.text.includes(otherCard.name)) {
                cards.push(otherCard);
            }
        }

        // If a stack card has been selected then get the rest of the pile
        for (let type of card.types) {
            if (stackPileCardTypes.includes(type)) {
                const others = this._otherCards.filter(x => x.types.includes(type));

                cards.push(...others);
            }
        }

        // Check for split cards
        const split = CardHelper.getSplitPile(card);

        if (split !== undefined && split.top === card.name) {
            cards.push(this.getCard(split.bottom)!);
        }

        for (const otherCardType of additionalCardTypes) {

            if (doNotShowAdditionalCardTypes.includes(otherCardType)) {
                continue;
            }

            if (card.text.includes(otherCardType)) {
                cardTypes.push(otherCardType);
            }
        }

        return { cards, cardTypes };
    }

    public getCard(name: string) {
        const card = this._allCards.find(x => x.name === name);

        if (card === undefined) {
            console.log(`Unable to find card: ${name}`);
        }

        return card;
    }

    public getSets(setNames: string[]): CardSet[] {
        const sets = [];

        for (const key of Object.keys(this.CardSets)) {
            for (const edition of Object.keys(this.CardSets[key])) {
                const set = this.CardSets[key][edition];

                if (setNames.includes(set.fullName)) {
                    sets.push(set);
                }
            }
        }

        return sets;
    }
}
