import { customCards as setRules } from "../Data/SetRules";
import { Card } from "../Entitites/Card";
import { AdditionalCards, CardSets } from "../Entitites/CardSet";
import { CardsMeta } from "../Entitites/MetaObjects/CardsMeta";
import { CardHelper } from "./CardHelper";

export const NonEditionName = "Core";

export class CardSetBuilder {

    public static toCardSets(cards: Card[]) {

        const cardSets: CardSets = {};
        const additionalCards: AdditionalCards = [];

        function getOrMakeSet(cardSetName: string) {

            const baseSetName = CardSetBuilder.trimToBaseSetName(cardSetName);

            if (!(baseSetName in cardSets)) {
                cardSets[baseSetName] = {};
            }

            const editionCardSet = cardSets[baseSetName];
            const editionName = CardSetBuilder.getEditionName(cardSetName, baseSetName);

            if (!(editionName in editionCardSet)) {
                editionCardSet[editionName] = {
                    cards: [],
                    optionalCardTypes: [],
                    fullName: cardSetName,
                    setName: baseSetName
                };
            }

            return editionCardSet[editionName];
        }

        // All cards that are part of stack piles (from Allies) are processed after the rest of the cards 
        const stackPiles: { [key: string]: Card[] } = {};

        for (const card of cards) {

            const cardSet = getOrMakeSet(card.set);

            if (CardHelper.isBottomOfSplitPile(card)) {
                additionalCards.push(card);
                continue;
            }

            const stackPile = CardHelper.stackPile(card);

            if (stackPile !== undefined) {
                // The card is part of a stackpile - save it for later
                if (!(stackPile in stackPiles)) {
                    stackPiles[stackPile] = [];
                }

                stackPiles[stackPile].push(card);
                continue;
            }

            if (CardHelper.isKingdom(card)) {

                cardSet.cards.push(card);
            } else if (CardHelper.isAdditional(card)) {
                additionalCards.push(card);

                const optionalType = CardHelper.optionalCardType(card);

                if (optionalType !== undefined && !cardSet.optionalCardTypes.includes(optionalType)) {
                    cardSet.optionalCardTypes.push(optionalType);
                }
            }
        }

        for (const set of Object.keys(setRules)) {
            for (const card of setRules[set]?.customCards ?? []) {
                if (set in cardSets) {
                    cardSets[set][NonEditionName].cards.push(card);
                }
            }
        }

        // For each stack pile get the cheapest card and add that to the set. Then put the rest of the stack pile cards into additionalCards
        for (const stackPile of Object.keys(stackPiles)) {
            const sortedPile = stackPiles[stackPile].sort((a, b) => a.cost > b.cost ? 1 : -1);

            const first = sortedPile[0];

            const set = getOrMakeSet(first.set);
            set.cards.push(first);

            sortedPile.splice(0, 1);
            additionalCards.push(...sortedPile);
        }



        const cardsMeta = new CardsMeta(cardSets, additionalCards);

        return cardsMeta;
    }

    private static trimToBaseSetName(cardSetName: string) {
        return cardSetName.endsWith("E") ? cardSetName.substring(0, cardSetName.length - 2).trim() : cardSetName;
    }

    private static getEditionName(cardSetName: string, baseSetName: string) {
        const editionName = cardSetName.replace(baseSetName, "").trim();

        if (editionName.length > 0) {
            return editionName;
        }

        return NonEditionName;
    }
}